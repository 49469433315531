export const PHOTO_PROPERTY_TYPE = {
  RECIPE: 'recipes',
  INGREDIENT: 'ingredients',
  APPLIANCE: 'appliances'
}

export const PHOTO_SIZE = {
  SIZE_16: 16,
  SIZE_32: 32,
  SIZE_64: 64,
  SIZE_128: 128,
  SIZE_256: 256,
  SIZE_512: 512,
  SIZE_1024: 1024,
  SIZE_2048: 2048
}

export const PHOTO_SIZE_SUFFIX_BY_SIZE = {
  [PHOTO_SIZE.SIZE_16]: '_16',
  [PHOTO_SIZE.SIZE_32]: '_32',
  [PHOTO_SIZE.SIZE_64]: '_64',
  [PHOTO_SIZE.SIZE_128]: '_128',
  [PHOTO_SIZE.SIZE_256]: '_256',
  [PHOTO_SIZE.SIZE_512]: '_512',
  [PHOTO_SIZE.SIZE_1024]: '_1024',
  [PHOTO_SIZE.SIZE_2048]: '_2048'
}

export const PHOTO_EXTENSION = {
  WEBP: '.webp'
}
